import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'


const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Angela Tuscher - Datenschutz</title>
            <meta name="description" content="Privacy Notice" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Impressum & Datenschutz</h1>
                    </header>
                    <ol style={{listStyleType: "upper-roman"}}>
                        <li>
                            <h4 class="">Name und Anschrift des Verantwortlichen</h4>
                            <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:</p>
                            <br /><br />
                            <p>Angela M. Tuscher<br />Neuffenstrasse 32<br />70806 Kornwestheim<br /> Telefon: +49 172 925 2702<br /> Email: mail@angelatuscher.com<br/> </p>
                        </li>
                        <li>
                            <h4 class="">Allgemeines zur Datenverarbeitung</h4>
                            <ol>
                                <li>
                                    <h5>Umfang der Datenverarbeitung</h5>
                                    <p>
                                        Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
                </p>
                                </li>
                                <li>
                                    <h5>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h5>
                                    <p>
                                        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage. Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind. Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;c DSGVO als Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;d DSGVO als Rechtsgrundlage. Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO als Rechtsgrundlage für die Verarbeitung.
                </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h4 class="mb-3">E-Mail-Kontakt</h4>
                            <ol>
                                <li>
                                    <h5>Beschreibung und Umfang der Datenverarbeitung</h5>
                Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben Daten an uns übermittelt und gespeichert. Diese Daten sind:
                <ol>
                                        <li>Vor- und Nachname des Nutzers</li>
                                        <li>E-Mail Adresse des Nutzers</li>
                                        <li>Die vom Nutzer per Kontaktformular übermittelte Nachricht</li>
                                    </ol>
                                    <br />
                                    <p>
                                        Zum Zeitpunkt der Absendung der Nachricht werden keine sonstigen Daten gespeichert.Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen. Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert. Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.
                </p>
                                </li>
                                <li>
                                    <h5>Rechtsgrundlage für die Datenverarbeitung</h5>
                                    <p>
                                        Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a DSGVO.
                </p>
                                    <p>
                                        Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO.
                </p>
                                </li>
                                <li>
                                    <h5>Zweck der Datenverarbeitung</h5>
                                    <p>Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten.
                </p>
                                </li>
                                <li>
                                    <h5>Dauer der Speicherung</h5>
                                    <p> Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist.
                </p>
                                </li>
                                <li>
                                    <h5>Widerspruchs- und Beseitigungsmöglichkeit</h5>
                                    <p> Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden. Um die Einwilligung zu Widerrufen ist es für den Nutzer lediglich notwendig, diesbezüglich mit uns Kontak aufzunehmen. Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall gelöscht.
                </p>
                                </li>
                            </ol>
                        </li>
                    </ol>

                </div>
            </section>
        </div>

    </Layout>
)

export default Generic